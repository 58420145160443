import React from "react";
import Landing from "../components/landing"



class Home extends React.Component {
    render() {
        return (
            <div>


                <Landing />
            </div>
        );
    }
}
export default Home;
