import React from "react";
import LiquorChoice from "../components/liquorChoice";
import { useAuth0 } from "@auth0/auth0-react";





export default function Choose() {
  
    
        return (
            <div >


                <LiquorChoice />

            </div>
        );
    }

